import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import CostBudget, { DetailedList, ShareList } from '../../../../components';

formCreate.component('CostBudget', CostBudget);
formCreate.component('DetailedList', DetailedList);
formCreate.component('ShareList', ShareList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20201207000000213',
      formFunctionCode: 'tpm_activity_department_receive_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;

      if (v.field === 'beginDate' || v.field === 'endDate') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'beginDate',
            endField: 'endDate',
            // gtNow: true,
          },
        };
      } else if (v.field === 'budgetSubjectCode') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'tpm_budget_list',
            // idKey: 'feeBudgetCodes',
            // parentCode: 'CRM20201201000000190',
            // requestUrl: '/tpm/tpmFeeBudgetControlController/list',
            // noReset: true,
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          state: this.formConfig.budgetState,
        };
      } else if (v.field === 'availableBalance') {
        v.props = {
          ...v.props,
          min: 0,
          precision: 2,
          controls: false,
        };

        v.value = undefined;
      } else if (v.field === 'categoriesCode') {
        v.restful = '/tpm/tpmActController/getCategoriesByBudget';
        v.restfulParams = { enableStatus: '009', actType: 'department_charge' };
        v.optionsKey = { label: 'categoriesName', value: 'categoriesCode' };
        v.restfulParamsGetValue = { controlIds: 'controlIds' };
        v.refresh = true;
      } else if (v.field === 'detailVos') {
        v.props = {
          ...v.props,
          parentCode: this.formParentCode,
          functionCode: 'tpm_detail_list',
          actType: 'department_charge',
          getValue: (field) => this.getFieldValue(field),
          setValue: (value) => this.setValue(value),
          hiddenFields: (hidden, fields) => this.hiddenFields(hidden, fields),
        };
      } else if (v.field === 'feeShareVos') {
        v.props = {
          ...v.props,
          getValue: (field) => this.getFieldValue(field),
        };
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(true, ['t3', 'feeShareVos']);

      const budgetSubjectCode = this.getRule('budgetSubjectCode');
      budgetSubjectCode.on.change = (e) => {
        let budgetControlVos = [];
        if (e) {
          budgetControlVos = e.map((v) => ({
            ...v,
            feeBudgetVos: v.feeBudgetVos.map((sub) => ({
              ...sub,
              reduceOrder: 1,
            })),
          }));
        }
        this.setValue({
          controlIds: e ? e.map((v) => v.id) : [],
          budgetSubjectCode: e ? e[0].feeBudgetCodes : '',
          budgetSubjectName: e ? e[0].budgetSubjectsName : '',
          availableBalance: e ? e[0].canUseAmount : undefined,
          categoriesCode: '',
          detailVos: [],
          budgetControlVos,
        });

        this.disabled(!e, 'categoriesCode');

        // this.updateRules({
        //   detailVos: {
        //     props: {
        //       functionCode: '',
        //     },
        //   },
        // });
      };

      const categoriesCode = this.getRule('categoriesCode');
      categoriesCode.on.change = (e) => {
        this.resetEditTable(e);
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view' || this.id) {
        request
          .get('/tpm/tpmActController/query', {
            id: this.formConfig.row.actId || this.formConfig.row.id || this.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;

              const { editBudgetVos, detailVos, fileList } = data;

              const controlIds = editBudgetVos ? [editBudgetVos[0].id] : [];

              data.budgetSubjectCode = editBudgetVos ? editBudgetVos[0].budgetSubjectsCode : [];
              data.controlIds = controlIds;
              data.budgetControlVos = editBudgetVos;
              data.fileList = fileList
                ? fileList.map((v) => ({
                  ...v,
                  url: v.fileAddress,
                  fileName: v.objectName,
                }))
                : [];
              this.setValue(data);

              this.updateRules({
                detailVos: {
                  props: {
                    // functionCode: detailVos[0].formCode || '',
                    categoriesCode: data.categoriesCode,
                  },
                },
              });
            }
          });
      }
    },
    // 重置明细/分摊表格数据
    resetEditTable(e) {
      this.setValue({
        detailVos: [],
        feeShareVos: [],
        // categoriesName: e.label
      });

      this.updateRules({
        detailVos: {
          props: {
            // functionCode: e ? e.formCode : '',
            categoriesCode: e || '',
          },
        },
      });

      this.hiddenFields(true, ['t3', 'feeShareVos']);
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmActController/save';
        const params = { ...formData };

        if (params.detailVos.length === 0) {
          this.$message.error('请填写活动明细');
          return false;
        }
        params.detailVos = params.detailVos.map((v) => ({
          ...v,
          // executeBeginDate: params.beginDate,
          // executeEndDate: params.endDate,
          feeDateStr: params.beginDate ? params.beginDate.substr(0, 7) : '',
        }));

        if (params.feeShareVos && params.feeShareVos.length > 0) {
          const sum = params.feeShareVos.reduce((acc, cur) => acc + Number(cur.shareRatio), 0);

          if (sum !== 100) {
            this.$message.error('分摊的产品比例和必须是100！');
            return false;
          }
        }

        params.saveType = e.toString();
        params.actType = 'department_receive';

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmActController/update';
          params.id = this.formConfig.row.actId || this.formConfig.row.id;
          params.saveType = (e + 2).toString();
        }

        if (e === 2) {
          this.$emit('submit', {
            row: params,
            submitUrl: url,
          });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
